import React from 'react'
import { Link } from 'gatsby'

// Components
import Layout from '../layouts'
import Nav from '../components/Nav'
import Footer from '../components/Footer'

// Utils
import {useIsMobile} from '../utils/hooks'

function TermsPage() {
  const isMobile = useIsMobile()

  return (
    <Layout pageName="terms">
      {isMobile ? null : <Nav/>}

      <div className="maxwidth-xsmall">
        <div className="content legal">
          <h1>Terms of Use</h1>
          <div className="last-revision">Last revision: 23rd May, 2017</div>
          <h3>Introduction</h3>
          <p>
            These Terms of Use (the “Terms”) govern your use of the Bizy, LLC
            (“Company”) website, located at bizy.com, our mobile application
            Bizy (“Application”), and our widgets (collectively, the “Site”) and
            online services (the “Services”).
          </p>
          <ol>
            <li>
              <h3>1. Acceptance of Terms of Use</h3>
              <p>
                Please carefully read the following Terms of Use (“Terms”)
                before using the Site, Application and their Services. By
                accessing and using this Site or Services or Application, you
                acknowledge that you have read, understood and agree to be bound
                by these Terms which form an agreement that is effective as if
                you had signed it. If at any time you do not agree to these
                Terms, please do not access or use the Site or Services or
                Application or any of its content.
              </p>
              <p>
                YOUR ACCESS TO, USE OF AND BROWSING OF THE SITE, SERVICES,
                APPLICATION, AND THE CONTENTS ARE SUBJECT TO ALL TERMS OF USE
                CONTAINED HEREIN AND ALL APPLICABLE LAWS AND REGULATIONS. IF YOU
                DO NOT AGREE TO THESE TERMS OF USE, YOUR PERMISSION TO ACCESS OR
                USE THE SITE, SERVICE, AND APPLICATION IS AUTOMATICALLY AND
                IMMEDIATELY REVOKED.
              </p>
              <p>
                These Terms may be revised or updated from time to time.
                Accordingly, you should check the Terms regularly for updates.
                You can determine when the Terms were last revised by referring
                to the “Last Revised” legend at the top of this page. Any
                changes in these Terms take effect upon posting and will only
                apply to use of the Services after that date. Each time you
                access, use or browse the Site or Services or Application, you
                signify your acceptance of the then-current Terms.
              </p>
            </li>
            <li>
              <h3>2. Permitted Users of Site or Services or Application</h3>
              <p>
                This Site, Services, and Application is directed to persons 18
                years of age or older. This Site, Services, Application and
                Company do not knowingly collect information from persons under
                age 18 or children under age 13. If you are under 18 years of
                age, you may visit, browse and use the information on the Site
                or Services or Application, but you may not submit any personal
                information to the Service. If you are under age 13, you are not
                permitted to use this Site or Services or Application or to
                submit any personally identifiable information to the Services.
              </p>
            </li>
            <li>
              <h3>3. Permitted Use of Site, Services, and Application</h3>
              <p>
                The content available through the Site, Services, and
                Application (the “Content”) is the sole and exclusive property
                of Company and/or its licensors. You agree not to reproduce,
                duplicate, copy, sell, resell or exploit for any commercial
                purpose, any portion of the Site or Services or Application or
                the Content other than as expressly authorized by Company in
                writing. Use of the Site, Application, Services or the Content
                in any way not expressly permitted by these Terms is prohibited,
                and may be actionable under United States or international law.
              </p>
              <p>
                So long as you agree and comply with these Terms, and unless
                these Terms are otherwise terminated or modified by Company, you
                are permitted to view and use the Site, Services, Application,
                and the Content solely for your own information and for purchase
                of the products or services offered here. You may not duplicate,
                publish, modify, distribute, perform or create derivative works
                from any part of the Site or Services or Application or the
                Content unless expressly authorized by Company. You agree that
                you will not remove or modify any acknowledgements, credits or
                legal notices contained on the Site or Services or Application
                or in the Content.
              </p>
              <p>
                Special terms may apply to some products or services offered on
                the Site or Services or Application, or to any sweepstakes,
                contests or promotions that may be offered on the Site or
                Services or Application. Such special terms (which may include
                official rules) may be posted in connection with the applicable
                product, service, sweepstakes, contest, promotion, feature or
                activity. Any such special terms are in addition to these Terms
                and, in the event of a conflict, any such terms shall prevail
                over these Terms.
              </p>
            </li>
            <li>
              <h3>4. Privacy Policy</h3>
              <p>
                Please review the Privacy Policy for the Site, Services, and
                Application at
                <Link to="/privacy"> https://www.bizy.com/privacy</Link>
                . If you do
                not agree with the Privacy Policy at
                <Link to="/privacy"> https://www.bizy.com/privacy</Link>
                , you may not
                use the Site or Services or Application.
              </p>
            </li>
            <li>
              <h3>5. Proprietary Rights</h3>
              <p>
                You acknowledge and agree that, as between Company and you, all
                right, title, and interest in and to the Site, Services,
                Application, and the Content, including without limitation any
                patents, copyrights, trademarks, trade secrets, inventions,
                know-how, and all other intellectual property rights are owned
                exclusively by Company or its licensors and are protected by
                United States intellectual property laws and other applicable
                laws.
              </p>
              <p className="small-heading">
                <strong>Copyright:</strong>
              </p>
              <p>
                All content included in the Site, Services, and Application,
                such as text, graphics, logos, icons, images, media, data,
                audio, animation, software and other information and materials,
                is the copyright and property of Company or its content
                suppliers and protected by U.S. and international copyright
                laws. Permission is granted to electronically copy and print
                hard copy portions of the Site, Services, and Application for
                the sole purpose of placing an order for Services; using the
                Site or Services or Application as a resource; or using the Site
                or Services or Application for personal information. Any other
                use, including the reproduction, modification, distribution,
                transmission, republication, display or performance, of the
                Content of the Site or Services or Application, is strictly
                prohibited.
              </p>
              <p className="small-heading">
                <strong>Trademarks:</strong>
              </p>
              <p>
                The trademarks, service marks, logos, slogans, trade names and
                trade dress used on the Site or Services or Application are
                proprietary to Company. Unauthorized use of any trademark of
                Company may be a violation of trademark laws. Any third party
                names or trademarks referenced in the Site or Services or
                Application do not constitute or imply affiliation, endorsement
                or recommendation by Company or of Company by the third parties.
              </p>
            </li>
            <li>
              <h3>6. Your Indemnity of Company</h3>
              <p>
                YOU AGREE TO INDEMNIFY, DEFEND AND HOLD COMPANY, ITS OFFICERS,
                DIRECTORS, EMPLOYEES, AGENTS, SUBSIDIARIES, AFFILIATES,
                LICENSORS, SERVICE PROVIDERS AND OTHERS ACTING IN CONCERT WITH
                IT, HARMLESS FROM ANY LOSS, LIABILITY, CLAIM OR DEMAND,
                INCLUDING WITHOUT LIMITATION REASONABLE ATTORNEYS' FEES, MADE BY
                YOU OR ON YOUR BEHALF OR BY ANY THIRD PARTY DUE TO OR ARISING
                OUT OF (A) YOUR CONNECTION OR SUBMISSION TO OR USE OF THE SITE
                OR SERVICES OR APPLICATION OR THE CONTENT; OR (B) YOUR VIOLATION
                OF THESE TERMS OF USE, ANY APPLICABLE LAWS, OR THE RIGHTS OF
                COMPANY OR ANY THIRD PARTY.
              </p>
            </li>
            <li>
              <h3>7. User Generated Content</h3>
              <p className="small-heading">
                <strong>Communications Services:</strong>
              </p>
              <p>
                The Site may contain galleries for blogs, chat areas, forums,
                comments and rankings, contests, communities, calendars, and/or
                other message or communication facilities designed to enable you
                and others to communicate with Bizy, the Site and other users of
                the Site (collectively, "Communication Services"). Where the
                Site seeks your submissions, you acknowledge that your
                submissions may be or become available to others. You agree to
                use the Communication Services only for your personal use in
                connection with your personal information and enjoyment of the
                Site. You agree only to post, send and receive messages and
                materials that are proper and related to the particular
                Communication Service.
              </p>
              <p className="small-heading">
                <strong>Prohibited Actions</strong>
              </p>
              <p>
                You agree that the following actions are prohibited and
                constitute a material breach of these Terms. This list is not
                meant to be exhaustive, and Company reserves the right to
                determine what types of conduct it considers to be inappropriate
                use of the Site. In the case of inappropriate use, the Site
                moderator may take such measures as it determines in its sole
                discretion.
              </p>
              <p>
                By way of example, and not as a limitation, you agree that when
                using a Communication Service, you will not:
              </p>
              <ul>
                <li>
                  <p>
                    Use the Site or the Content for any purpose or make any
                    other actions in violation of local, state, national, or
                    international laws or regulations.
                  </p>
                </li>
                <li>
                  <p>
                    Violate any code of conduct or other guidelines which may be
                    applicable for any particular Communication Service.
                  </p>
                </li>
                <li>
                  <p>
                    Take any action that imposes an unreasonable or
                    disproportionately large load on the Site's infrastructure
                    or otherwise in a manner that may adversely affect
                    performance of the Site or restrict or inhibit any other
                    user from using and enjoying the Communication Services or
                    the Site.
                  </p>
                </li>
                <li>
                  <p>
                    Use the Site for unauthorized framing of or linking to, or
                    access via automated devices, bots, agents, scraping,
                    scripts, intelligent search or any similar means of access
                    to the Content or any other materials or information
                    available from the Site.
                  </p>
                </li>
                <li>
                  <p>
                    Aggregate, copy, duplicate, publish, or make available to
                    third parties outside the Site in any manner any of the
                    Content or any other materials or information available from
                    the Site.
                  </p>
                </li>
                <li>
                  <p>
                    Defame, abuse, harass, stalk, threaten or otherwise violate
                    the legal rights (such as rights of privacy and publicity)
                    of others.
                  </p>
                </li>
                <li>
                  <p>
                    Publish, post, upload, distribute or disseminate any
                    inappropriate, profane, defamatory, infringing, obscene,
                    indecent or unlawful topic, name, material or information.
                  </p>
                </li>
                <li>
                  <p>
                    Upload or download files that contain software or other
                    material protected by intellectual property laws or other
                    laws, unless you own or control the rights thereto or have
                    received all necessary consents.
                  </p>
                </li>
                <li>
                  <p>
                    Upload files that contain viruses, corrupted files, or any
                    other similar software or programs that may damage the
                    operation of another's computer.
                  </p>
                </li>
                <li>
                  <p>
                    Use the Site to make available unsolicited advertising or
                    promotional materials, spam, pyramid schemes, chain letters,
                    or similar forms of unauthorized advertising or
                    solicitation.
                  </p>
                </li>
                <li>
                  <p>
                    Harvest or otherwise collect information about others,
                    including e-mail addresses, without their consent.
                  </p>
                </li>
                <li>
                  <p>
                    Falsify or delete any author attributions, legal or other
                    proper notices or proprietary designations or labels of the
                    origin or source of software or other material contained in
                    a file that is uploaded.
                  </p>
                </li>
                <li>
                  <p>
                    Engage in any other action that, in the judgment of Company,
                    exposes it or any third party to potential liability or
                    detriment of any type.
                  </p>
                </li>
              </ul>
              <p className="small-heading">
                <strong>User Materials:</strong>
              </p>
              <p>
                Any content or views posted, shared, transmitted or otherwise
                made available by users through the Site (“User Materials”) are
                strictly those of the originating author, who is solely
                responsible for its content. Use of or reliance on User
                Materials is entirely at your own risk. Company does not endorse
                any User Materials nor vouch for their reliability. Under no
                circumstances will Company be liable in any way for any User
                Materials.
              </p>
              <p>
                You acknowledge that Company may or may not pre-screen User
                Materials, but that it and its designees have the right (but not
                the obligation) in their sole discretion to pre-screen, refuse,
                delete and/or move any User Materials that are available via the
                Site. Without limiting the foregoing, Company and its designees
                have the right to remove any User Materials that violate these
                Terms or are otherwise objectionable in Company’s sole
                discretion. Company shall have no liability for such removal.
              </p>
              <p>
                You understand that by using the Site, you may be exposed to
                User Materials that you may consider to be offensive or
                objectionable. You agree that you must evaluate, and bear all
                risks associated with, the use or exposure to any User Materials
                posted by others. You further acknowledge and agree that you
                will not rely on any Content available on or through the Site.
              </p>
              <p className="small-heading">
                <strong>Submissions:</strong>
              </p>
              <p>
                You are solely responsible for the User Materials that you post,
                share, email, transmit or otherwise make available via the Site
                (“Submission”). All Submissions are subject to these Terms.
                Company is under no obligation to post or use any Submission and
                may remove any Submission at any time in its sole discretion.
              </p>
              <p>
                By making a Submission, you represent and warrant that your
                Submission is true, your own original work, and does not
                infringe any other person’s or entity’s rights, and that you and
                any other person mentioned or shown in your Submission release
                any and all claims concerning Company’s or its designees’ use,
                modification or distribution of the Submission or any part
                thereof. You must own all rights, including copyright, to your
                Submission, and must hold all necessary releases concerning the
                contents of your Submission. You agree that you must evaluate,
                and bear all risks associated with, your disclosure of any
                Submission.
              </p>
              <p>
                By making a Submission, you grant Company and its licensees,
                assignees and designees an irrevocable, assignable, fully
                sub-licensable, perpetual, world-wide, royalty-free,
                non-exclusive license, in their sole discretion, to use,
                distribute, reproduce, modify, combine, adapt, publish,
                translate, rent, lease, sell, publicly perform and publicly
                display your Submission (in whole or in part), along with your
                name or any part thereof and state of residency, in Company’s
                discretion, on the Site or elsewhere, and to use or incorporate
                all or any part of your Submission into other advertising,
                promotion, research, analysis or other materials in any format
                or medium now known or later developed. You hereby waive any
                right to inspect such use and any claims based on privacy,
                publicity, defamation, misappropriation, intellectual property
                or similar claims for any use of your Submission.
              </p>
            </li>
            <li>
              <h3>
                8. Notice and Procedures for Making Claims of Copyright or
                Intellectual Property Infringement
              </h3>
              <p>
                Company may, in appropriate circumstances and at its sole
                discretion, disable and/or terminate use by users who infringe
                the intellectual property of others. If you believe that your
                work has been copied in a way that constitutes copyright
                infringement, or your intellectual property rights have been
                otherwise violated, please provide Company’s Copyright Agent a
                Notice containing the following information:
              </p>
              <ul>
                <li>
                  <p>
                    an electronic or physical signature of the person authorized
                    to act on behalf of the owner of the copyright or other
                    intellectual property interest;
                  </p>
                </li>
                <li>
                  <p>
                    a description of the copyrighted work or other intellectual
                    property that you claim has been infringed;
                  </p>
                </li>
                <li>
                  <p>
                    a description of where the material that you claim is
                    infringing is located on the Site or Services or Application
                    (providing URL(s) in the body of an email is the best way to
                    help Company locate content quickly);
                  </p>
                </li>
                <li>
                  <p>your address, telephone number, and email address;</p>
                </li>
                <li>
                  <p>
                    a statement by you that you have a good faith belief that
                    the disputed use is not authorized by the copyright owner,
                    its agent, or the law;
                  </p>
                </li>
                <li>
                  <p>
                    a statement by you, made under penalty of perjury, that the
                    above information in your Notice is accurate and that you
                    are the copyright or intellectual property owner or
                    authorized to act on the copyright or intellectual property
                    owner’s behalf.
                  </p>
                </li>
              </ul>
              <p>
                Company’s Copyright Agent for Notice of claims of copyright or
                other intellectual property infringement can be reached in the
                following ways:
              </p>
              <div className="contacts">
                <div className="contacts__item">
                  <p className="contacts__item-title">
                    <strong>Address:</strong>
                  </p>
                  <p className="contacts__item-text">Bizy Copyright Agent</p>
                  <p className="contacts__item-text">3000 K Street NW</p>
                  <p className="contacts__item-text">STE 275</p>
                  <p className="contacts__item-text">Washington, DC 20007</p>
                </div>
                <div className="contacts__item">
                  <p className="contacts__item-title">
                    <strong>Telephone:</strong>
                  </p>
                  <p className="contacts__item-text">800-644-5102</p>
                </div>
                <div className="contacts__item">
                  <p className="contacts__item-title">
                    <strong>Email Address:</strong>
                  </p>
                  <p className="contacts__item-text">
                    <a href="mailto:legal@bizy.com">legal@bizy.com</a>
                  </p>
                </div>
              </div>
            </li>
            <li>
              <h3>9. Links</h3>
              <p className="small-heading">
                <strong>Links to Other Websites and Search Results:</strong>
              </p>
              <p>
                The Site, Services, and Application may contain links to
                websites operated by other parties. The Site, Services, and
                Application provides these links to other websites as a
                convenience, and your use of these sites is at your own risk.
                The linked sites are not under the control of Company and
                Company is not responsible for the content available on these
                third party sites. Such links do not imply endorsement of
                information or material on any other site and Company disclaims
                all liability with regard to your access to, use of or
                transactions with such linked websites. You acknowledge and
                agree that Company shall not be responsible or liable, directly
                or indirectly, for any damage, loss or other claim caused or
                alleged to be caused by or in connection with, access to, use of
                or reliance on any content available on or through any other
                site or resource.
              </p>
              <p className="small-heading">
                <strong>Links to the Site or Services or Application:</strong>
              </p>
              <p>
                You may link another website to the Site subject to the
                following linking policy: (i) the appearance, position and other
                aspects of any link may not be such as to damage or dilute the
                reputation of Company or the Site; (ii) the appearance, position
                and other attributes of the link may not create the false
                appearance that your site, business, organization or entity is
                sponsored by, affiliated with, or associated with Company or the
                Site; (iii) when selected by a user, the link must display the
                site on full-screen and not within a "frame" on the linking
                website; and (iv) Company reserves the right to revoke its
                consent to the link at any time and in its sole discretion.
              </p>
            </li>
            <li>
              <h3>10. Modifications to Site or Services or Application</h3>
              <p>
                Company reserves the right at any time and from time to time to
                modify or discontinue, temporarily or permanently, the Site or
                Services or Application, or any portion thereof, with or without
                notice. You agree that Company shall not be liable to you or to
                any third party for any modification, suspension, or
                discontinuance of the Site or Services or Application.
              </p>
            </li>
            <li>
              <h3>11. Suspension and Termination Rights</h3>
              <p>
                Company reserves the right, at its sole discretion, immediately
                and without notice, to suspend or terminate your access to the
                Services for any reason, including without limitation any breach
                by you of these Terms. You agree that Company shall not be
                liable to you or any third party for any such suspension or
                termination.
              </p>
            </li>
            <li>
              <h3>12. Disclaimer</h3>
              <p>
                THE SITE, SERVICES, AND APPLICATION AND CONTENT AND THE
                INFORMATION, SERVICES, PRODUCTS OFFERED FOR SALE AND MATERIALS
                AND ACTIVITIES CONTAINED IN OR ADVERTISED ON THE SITE, SERVICES,
                AND APPLICATION, INCLUDING WITHOUT LIMITATION TEXT, GRAPHICS AND
                LINKS, ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS
                WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. TO
                THE MAXIMUM EXTENT PERMITTED BY LAW, COMPANY AND ITS SUPPLIERS
                AND RELATED PARTIES DISCLAIM ALL REPRESENTATIONS AND WARRANTIES,
                EXPRESS OR IMPLIED, WITH RESPECT TO SUCH INFORMATION, SERVICES,
                PRODUCTS AND MATERIALS, INCLUDING, BUT NOT LIMITED TO,
                WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
                PURPOSE, TITLE, NON-INFRINGEMENT, FREEDOM FROM COMPUTER VIRUS
                AND IMPLIED WARRANTIES ARISING FROM COURSE OF DEALING OR COURSE
                OF PERFORMANCE.
              </p>
              <p>
                Without limiting the foregoing, you are responsible for taking
                all necessary precautions to insure that any Content or access
                to the Site or Services or Application is free of viruses or
                other harmful code.
              </p>
            </li>
            <li>
              <h3>13. Limitation on Liability</h3>
              <p>
                TO THE MAXIMUM EXTENT PERMITTED BY LAW, COMPANY AND ITS RELATED
                PARTIES DISCLAIM ALL LIABILITY, WHETHER BASED IN CONTRACT, TORT
                (INCLUDING WITHOUT LIMITATION NEGLIGENCE), STRICT LIABILITY OR
                ANY OTHER THEORY ARISING OUT OF OR IN CONNECTION WITH THE SITE
                OR SERVICES OR APPLICATION, USE, INABILITY TO USE OR PERFORMANCE
                OF THE INFORMATION, SERVICES, PRODUCTS AND MATERIALS AVAILABLE
                FROM THE SITE, SERVICES, AND APPLICATION. IN NO EVENT SHALL BIZY
                OR ANY OF ITS AFFILIATED ENTITIES OR SUPPLIERS BE LIABLE FOR ANY
                DIRECT, INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL, EXEMPLARY OR
                CONSEQUENTIAL DAMAGES, EVEN IF THESE ENTITIES HAVE BEEN
                PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THESE
                LIMITATIONS SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL
                PURPOSE OR THE EXISTENCE OF ANY LIMITED REMEDY. WITHOUT LIMITING
                THE FOREGOING, THE MAXIMUM AGGREGATE LIABILITY OF COMPANY
                ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OF USE OR THE
                SITE OR SERVICES OR APPLICATION SHALL NOT EXCEED FIFTY DOLLARS
                (U.S.).
              </p>
              <p className="small-heading">
                <strong>Exclusions and Limitations:</strong>
              </p>
              <p>
                Because some jurisdictions do not allow limitations on how long
                an implied warranty lasts, or the exclusion or limitation of
                liability for consequential or incidental damages, the above
                limitations may not apply to you. This Limitation of Liability
                shall be to the maximum extent permitted by applicable law.
              </p>
              <p />
            </li>
            <li>
              <h3>14. Governing Law and Disputes</h3>
              <p>
                These Terms shall be governed by, and will be construed under,
                the laws of the State of Delaware, U.S.A., without regard to
                choice of law principles. You irrevocably agree to the exclusive
                jurisdiction by the federal and state courts located in or for
                Company County, in the State of Delaware, U.S.A., to settle any
                dispute which may arise out of, under, or in connection with
                these Terms, as the most convenient and appropriate for the
                resolution of disputes concerning these Terms. Any cause of
                action or claim you may have with respect to these Terms,
                Company, the Site or Services or Application or its Content must
                be commenced within six (6) months after the claim or cause of
                action arises or such claim or cause of action shall be barred.
              </p>
              <p>
                The Site, Services, and Application are controlled within the
                United States of America. Those who choose to access the Site or
                Services or Application from locations outside of the United
                States do so on their own initiative, and are responsible for
                compliance with local laws if and to the extent local laws are
                applicable. Company does not represent that the Site or Services
                or Application or Content are appropriate outside the United
                States of America.
              </p>
            </li>
            <li>
              <h3>15. Force Majeure</h3>
              <p>
                Company shall not be liable for any delay or failure to perform
                resulting from causes outside its reasonable control or
                unforeseen circumstances such as acts of nature or God, fire,
                flood, earthquake, accidents, strikes, war, terrorism,
                governmental act, failure of common carriers (including without
                limitation Internet service providers and web hosting
                providers), or shortages of transportation facilities, fuel,
                energy, labor or materials.
              </p>
            </li>
            <li>
              <h3>16. Reliance on Information Posted</h3>
              <p>
                The information presented on or through the Website is made
                available solely for general information purposes. We do not
                make any statements regarding the accuracy, completeness or
                usefulness of this information. Any reliance you place on such
                information is strictly at your own risk. The Website includes
                content provided by third parties, including materials provided
                by other users and third-party licensors. We are not
                responsible, or liable to you or any third-party, for the
                content or accuracy of materials provided by any third parties.
              </p>
            </li>
            <li>
              <h3>17. Miscellaneous</h3>
              <p>
                These Terms set forth the entire understanding and agreement
                between you and Company with respect to the subject matter
                hereof. If any provision of the Terms is found by a court of
                competent jurisdiction to be invalid, the parties nevertheless
                agree that the court should endeavor to give effect to the
                parties' intentions as reflected in the provision, and the other
                provisions of the Terms shall remain in full force and effect.
                Headings are for reference only and in no way define, limit,
                construe or describe the scope or extent of such section.
                Company’s failure to act with respect to any failure by you or
                others to comply with these Terms does not waive its right to
                act with respect to subsequent or similar failures. You may not
                assign or transfer your rights or obligations under these Terms
                without the prior written consent of Company, and any assignment
                or transfer in violation of this provision shall be null and
                void. There are no third party beneficiaries to these Terms.
              </p>
            </li>
            <li>
              <h3>18. Questions?</h3>
              <p>
                Please direct any questions you may have about these Terms of
                Use, technical questions or problems with the Site or Services
                or Application, or comments or suggestions to Company at{' '}
                <a href="mailto:legal@Bizy.com">legal@Bizy.com</a>
              </p>
            </li>
          </ol>
        </div>
      </div>

      {isMobile ? null : <Footer/>}
    </Layout>
  )
}

export default TermsPage
